.div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    /* overflow: hidden; */
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    display: flex;
    height: 100vh;
    max-height: none;
    /*padding: 67px 60px;*/
  }
  @media (max-width: 991px) {
    .div {
      padding: 0 20px;
    }
  }
  .img {
    position: absolute;
    inset: 0;
    height: 100vh;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }


/* Main Body */

  .main {
    position: relative;
    border-radius: 35px;
    background: linear-gradient(
      180deg,
      rgba(24, 33, 42, 0.7) 0%,
      rgba(24, 33, 42, 0.35) 100%
    );
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(2.5px);
    display: flex;
    width: 800px;
    max-width: 90%;
    flex-direction: column;
    /*margin: auto;*/
    padding: 30px 28px;
    /* overflow: auto; */
  }


  .general {
    border-bottom: solid;
    border-color: rgba(111, 111, 111, 0.65);
    padding: 20px;

    gap: 20px;
    display: flex;
  }

  @media (max-width: 991px) {
    .general {
      max-width: 100%;
      flex-direction: column;
      align-items: stretch;
      gap: 0px;
    }
  }


/* Description */


  .description {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    color: #fff;
    font-weight: 400;
    text-align: center;
    margin: auto 0;

    width: 73%;
  }
  @media (max-width: 991px) {
    .description {
      margin-top: 40px;
    }
  }

  .d-stuff {
    justify-content: center;
    align-self: start;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    text-align: left;
    padding: 10px;
    gap: 10px;
    font-family: Inter, sans-serif;
    font-weight: 500;
  }


  .pfp {
    display: flex;
    flex-direction: column;
    line-height: normal;
    /*width: 27%;
    margin-left: 0px;*/

    background-color: #fff;
    width: 130px;
    height: 130px;
    margin: auto;
    border-radius: 4px;
  }


  @media (max-width: 991px) {
    .pfp {
      margin-top: 40px;
    }
  }


/* Socials */

  .socials {
    gap: 5px;
    font-size: 14px;
    white-space: nowrap;
    margin-right: auto;
    text-align: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 10px;
  }

  @media (max-width: 991px) {
    .socials {
      white-space: initial;
    }
  }

  .social-txt {
    margin: auto 0;
    font: 500 16px Inter, sans-serif;
  }

  .social-btn {
    font-family: Inter, sans-serif;
    justify-content: center;
    background-color: rgba(113, 118, 133, 0.36);
    padding: 8px 25px;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
  }

  @media (max-width: 991px) {
    .social-btn {
      white-space: initial;
      padding: 8px 15px;
    }
  }

  .social-btn:hover {
    background-color: rgba(113, 118, 133, 0.6);
  }

/* Modules */

  .modules {
    gap: 20px;
    display: flex;
    justify-content: center;
    align-content: space-between;
    flex-wrap: wrap;
    margin-top: 50px;
    padding: 0 33px 33px;
  }

  @media (max-width: 991px) {
    .modules {
      max-width: 100%;
      margin-top: 40px;
      padding: 0 20px;
    }
  }

  .module {
    height: fit-content;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: #1f272f;
    display: flex;
    /* width: 100%; */
    flex: 1;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
  }

  .module-hidden {
    height: fit-content;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
  }

  .module-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
  }

  .m-icon {
    justify-self: center;
    align-self: center;
    fill: #fff;
  }

  .m-bar {
    align-content: flex-start;
    gap: 5px;
    display: flex;
    flex-direction: row;
    /*margin-right: auto;*/
    width: 100%;
  }

  .m-title {
    color: #fff;
    flex: 1;
    /*text-align: center;*/
    width: auto;
    flex-grow: 1;
    /*font: 600 16px Inter, sans-serif;*/
    font: 500 16px Inter, sans-serif;
  }

  .m-sec {
    color: var(--TXT-Sec, #aaa);
    text-align: center;
    font: 300 14px Inter, sans-serif;
  }

  .m-loading {
    color: #fff;
    font: 500 16px Inter, sans-serif;
  }



/* Game Module */


  .m-game-rc {
    height: 150px;
    width: 100%;
    border-radius: 3px;
    /*box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);*/
    overflow: hidden;
  }
  /*.m-game-bar {
    background-color: var(--m-game-Bar, #414141);
    position: absolute;
    top: 0px;
    left: 24px;
    width: 54px;
    height: 129px;
  }*/
  .m-game-hd-img {
    aspect-ratio: 2.14;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    filter: blur(6px);
  }
  .m-game-info {
    bottom: 50px;
    left: 15px;
    align-self: start;
    position: relative;
    /*display: flex;
    flex-direction: column;*/
  }
  .m-game-title {
    color: #fff;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);
    font: 600 20px Inter, sans-serif;
  }
  .m-game-time {
    color: var(--TXT-Sec, #aaa);
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);
    font: 500 16px Inter, sans-serif;
  }

  /* Location Module */

  .m-location {
    color: #fff;
    font: 500 16px Inter, sans-serif;
    /* height: 100%; */
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: space-between;
  }

  .m-location-map {
    height: 100px;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
  }

  /*Disable MapboxGL Controls*/
  .mapboxgl-control-container {
    display: none;
  }

  .m-location-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .m-location-info div {
    display: flex;
    gap: 5px;
    flex-direction: row;
    align-items: center;
  }